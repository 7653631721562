import { RelativeHumidity } from "../RelativeHumidity/RelativeHumidity";
import SunMoonWidget from "../SunMoon/SunMoonWidget";
import RainWidget from "../Rain/RainWidget";
import LeafBinWidget from "../LeafWetBin/LeafBinWidget";
import {Temperature} from "../Temperature/Temperature";
import "./MetricCard.scss";
import "./CardBody.scss";
import "./BigValue.scss";
import {Wind} from "../Wind/Wind";
import {UVIndex} from "../UVIndex/UVIndex";
import {SolarRadiation} from "../SolarRadiation/SolarRadiation";
import { Humidity } from "../Humidity/Humidity";
import { Ambient } from "../Ambient/Ambient";
import { PM25Outdoor } from "../PM25Outdoor/PM25Outdoor";
import { TempRH } from "../TempRH/TempRH";
import { LeakDetect } from "../LeakDetect/LeakDetect";
import {Pressure} from "../Pressure/Pressure";
import EvapoTransWidget from "../EvapoTrans/EvapoTransWidget";
import { ForecastWidget } from "../ForecastWidget/ForecastWidget";
import { DailyForecastWidget } from "../DailyForecastWidget/DailyForecastWidget";
import { WeeklyForecastWidget } from "../WeeklyForecastWidget/WeeklyForecastWidget";
import {TempOutdoor} from "../Outdoor/TempOutdoor";
import { WebcamWidget } from "../Webcam/WebcamWidget";
import {Lighting} from "../Lightning/Lightning";
import {IndoorAirQuality} from "../IndoorAirQuality/IndoorAirQuality";
import {SoilTension} from "../SoilTension/SoilTension";
import {ErrorWidget} from "../ErrorWidget/ErrorWidget";
import { path } from "ramda";
import {Currentcast} from "../Currentcast/Currentcast";
import {Daycast} from "../Daycast/Daycast";
import {SoilMoisture} from "../SoilMoisture/SoilMoisture";
import {WindRose} from "../WindRose/WindRose";
import {Time} from "../Time/Time";
import {DewPoint} from "../DewPoint/DewPoint";
import {AQIN} from "../AQIN/AQIN";

export function Widget ({ type, device, size }) {
  if (!device) {
    return <ErrorWidget type='no-device' />;
  }
  // old data error
  const widgetsThatDontNeedData = [
    "forecast", 
    "daily-forecast", 
    "weekly-forecast", 
    "daycast",
    "currentcast",
    "webcam", 
    "sun-moon", 
    "time"
  ];
  if (!widgetsThatDontNeedData.includes(type) && path(['lastData', 'error'], device) === 'old-data') {
    return <ErrorWidget currentDevice={device} type='old-data' />;
  }
  if (/^soiltens/.test(type)) {
    return <SoilTension currentDevice={device} type={type} size={size}/>;
  } else if (/^soil/.test(type)) {
    return <SoilMoisture currentDevice={device} type={type} size={size}/>;
  }
  if (/^sensor/.test(type)) {
    return <TempRH currentDevice={device} type={type} size={size}/>;
  }

  switch (type) {
    case "humidity": return <Humidity currentDevice={device} size={size}/>;
    case "co2": return <IndoorAirQuality currentDevice={device} size={size}/>;
    case "co2_in": return <IndoorAirQuality currentDevice={device} type="co2_in" size={size}/>;
    case "sun-moon": return <SunMoonWidget currentDevice={device} size={size} />;
    case "temp": return <Temperature currentDevice={device}/>;
    case "wind": return <Wind currentDevice={device} size={size}/>;
    case "uv": return <UVIndex currentDevice={device}/>;
    case "r-humidity": return <RelativeHumidity currentDevice={device} size={size}/>;
    case "solarradiation": return <SolarRadiation currentDevice={device} size={size}/>;
    case "gdd": return <Ambient currentDevice={device} param='gdd' size={size} />;
    case "pm25": return <PM25Outdoor currentDevice={device} size={size} type={type}/>;
    case "pm25_in": return <PM25Outdoor currentDevice={device} size={size} type={type}/>;
    case "rain": return <RainWidget currentDevice={device} size={size}/>;
    case "leafwetbin": return <LeafBinWidget currentDevice={device} size={size}/>;
    case "leak": return <LeakDetect currentDevice={device} size={size}/>;
    case "pressure": return <Pressure currentDevice={device} size={size}/>;
    case "forecast": return <ForecastWidget/>;
    case "daily-forecast": return <DailyForecastWidget/>;
    case "weekly-forecast": return <WeeklyForecastWidget/>;
    case "outdoor": return <TempOutdoor currentDevice={device} size={size}/>;
    case "webcam": return <WebcamWidget currentDevice={device}/>;
    case "etos": return <EvapoTransWidget currentDevice={device} size={size}/>;
    case "lightning": return <Lighting currentDevice={device} size={size}/>;
    case "currentcast": return <Currentcast/>;
    case "daycast": return <Daycast size={size}/>;
    case "indoor": return <TempRH currentDevice={device} type="indoor" size={size}/>;
    case "tempfkestrel": return <TempRH currentDevice={device} size={size} type="indoor"/>;
    case "outdoor-temp-hum": return <TempRH type='outdoor-temp-hum' currentDevice={device} size={size}/>;
    case "aqin": return <AQIN currentDevice={device} size={size}/>;
    case "dew-point": return <DewPoint currentDevice={device} size={size}/>;
    case "wind-rose": return <WindRose currentDevice={device} size={size}/>;
    case "time": return <Time/>;
    default: return <div></div>;
  }
}
