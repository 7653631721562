import data from "./ambient/data.json";
import astro from "./ambient/astro";
import {getAstroValue} from "./ambient";
import {path} from "ramda";
import moment from "moment";
import cloudyDay from "./assets/backgrounds/Weather_Window_Cloudy_Day.png";
import cloudyNight from "./assets/backgrounds/Weather_Window_Cloudy_Night.png";
import frozenDay from "./assets/backgrounds/Weather_Window_Frozen_Day.png";
import frozenNight from "./assets/backgrounds/Weather_Window_Frozen_Night.png";
import partlyCloudyDay from "./assets/backgrounds/Weather_Window_Partly_Cloudy_Day.png";
import partlyCloudyNight from "./assets/backgrounds/Weather_Window_Partly_Cloudy_Night.png";
import genericLight from "./assets/backgrounds/Weather_Window_Generic_Light.png";
import genericDark from "./assets/backgrounds/Weather_Window_Generic_Dark.png";
import lightningDay from "./assets/backgrounds/Weather_Window_Lightning_Day.png";
import lightningNight from "./assets/backgrounds/Weather_Window_Lightning_Night.png";
import rainyDay from "./assets/backgrounds/Weather_Window_Rainy_Day.png";
import rainyNight from "./assets/backgrounds/Weather_Window_Rainy_Night.png";
import clearDay from "./assets/backgrounds/Weather_Window_Clear_Day.png";
import clearNight from "./assets/backgrounds/Weather_Window_Clear_Night.png";

export const getShortMeridian = (date) => {
  return date.format("a").substring(0, 1);
}
export const isDay = (date, lat, lon, tz) => {
  const getValue = getAstroValue({lat: lat, lon: lon, tz: tz});
  const sunrise = getValue(astro.solar.rise, Date.now());
  const sunset = getValue(astro.solar.set, Date.now());
  return date.isSameOrAfter(sunrise) && date.isBefore(sunset);
}
const isObjectJSON = function (obj) {
  return obj && typeof obj === 'object' && !Array.isArray(obj);
};
export const getWidthOfText = (text, styles) => {
  const element = document.createElement('span');
  if (isObjectJSON(styles)) {
    const styleKeys = Object.keys(styles);
    for (let i = 0, n = styleKeys.length; i < n; ++i) {
      element.style[styleKeys[i]] = styles[styleKeys[i]];
    }
  }
  element.style.display = 'inline-block';
  element.innerHTML = text;
  document.body.appendChild(element);
  let width = element.getBoundingClientRect().width;
  document.body.removeChild(element);

  return width;
};
export const getHeightOfParagraph = (text, fontStyle, width) => {
  const element = document.createElement('span');
  if (isObjectJSON(fontStyle)) {
    const styleKeys = Object.keys(fontStyle);
    for (let i = 0, n = styleKeys.length; i < n; ++i) {
      element.style[styleKeys[i]] = fontStyle[styleKeys[i]];
    }
  }
  element.style.display = 'inline-block';
  element.style.width = width;
  element.innerHTML = text;
  document.body.appendChild(element);
  const height = element.getBoundingClientRect().height;
  document.body.removeChild(element);
  return height;
};
export const countDecimals = (value) => {
  const numberVal = Number(value);
  if (!isNaN(numberVal)) {
    const parts = value.toString().split(".");
    if(parts.length > 1)
      return value.toString().split(".")[1].length || 0;
  }
  return 0;
}

export const getData = () => {
  return data;
}

export const resizeUntilFits = (val, fontStyle, availableWidth) => {
  let currentFontSize = fontStyle.fontSize.match(/\d+/)[0];
  const unit = fontStyle.fontSize.replace(/[0-9.]/g, "");
  const decrement = currentFontSize / 20;
  const minimumSize = currentFontSize * 0.4;
  let valSize = fontStyle.fontSize;
  while (currentFontSize > minimumSize) {
    const newWidth = getWidthOfText(val, {
      ...fontStyle,
      fontSize: currentFontSize + unit
    });
    valSize = currentFontSize + unit;

    if(newWidth <= availableWidth) break;

    currentFontSize = currentFontSize - decrement;
  }

  return valSize;
}


export const retrieveBackgroundImageUrl = (screenId, data) => {

  // forecast screen has no dynamic url, only dynamic background has
  if (screenId === 2 || data.theme !== "dynamic") {
    return null;
  }

  let backgroundImageUrl = null;
  const icon = path(['forecast', 'currently', 'icon'], data);
  const isLight = isDay(moment(), data.forecast.lat, data.forecast.lon, data.forecast.tz);

  switch (icon) {
    case 'cloudy':
      backgroundImageUrl = isLight ? cloudyDay : cloudyNight;
      break;
    case 'partly-cloudy-day':
    case 'part-sun':
    case 'partly-cloudy-night':
      backgroundImageUrl = isLight ? partlyCloudyDay : partlyCloudyNight;
      break;
    case 'hail':
    case 'flurries':
    case 'snow':
    case 'sleet':
      backgroundImageUrl = isLight ? frozenDay : frozenNight;
      break;
    case 'fog':
    case 'wind':
      backgroundImageUrl = isLight ? genericLight : genericDark;
      break;
    case 'lightning':
    case 'thunderstorm':
      backgroundImageUrl = isLight ? lightningDay : lightningNight;
      break;
    case 'rain':
    case 'sprinkles':
      backgroundImageUrl = isLight ? rainyDay : rainyNight;
      break;
    case 'clear':
    case 'clear-night':
      backgroundImageUrl = isLight ? clearDay : clearNight;
      break;
    default:
      backgroundImageUrl = isLight ? clearDay : clearNight;
  }

  return backgroundImageUrl;
};

export const toRadian = (d) => {
  return (Math.PI / 180.0) * d
}
export const makeId = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const weekDays = {
  Sunday: "S",
  Monday: "M",
  Tuesday: "T",
  Wednesday: "W",
  Thursday: "Th",
  Friday: "F",
  Saturday: "Sa",
};

export const getDateAndTimeFormattedByUserSettingsAndTimezone = (settings, timezone) => {
  if (settings.hour24 && settings.hour24.unit === 1) {
    return moment().tz(timezone).format("dddd, MMMM D  •  H:mm");
  } else {
    return moment().tz(timezone).format("dddd, MMMM D  •  h:mm") + getShortMeridian(moment().tz(timezone));
  }
}

export const convertToDateAndTimeFormattedByUserSettingsAndTimezone = (settings, timezone, timestamp) => {
  if (settings.hour24 && settings.hour24.unit === 1) {
    return moment(timestamp).tz(timezone).format("MMMM D, H:mm");
  } else {
    return moment(timestamp).tz(timezone).format("MMMM D, h:mm") + getShortMeridian(moment().tz(timezone));
  }
}

export const getWxwInfo = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return {
    macAddress: urlParams.get('macAddress') || '00:C5:26:21:45:4B',
    // macAddress: urlParams.get('macAddress') || 'C4:60:35:36:FC:56',
    key: urlParams.get('key') || '0d7ae4b83398c89322a182d7ffa484f57cfdad979c009ec3d2cfa7684b82e250',
    m: urlParams.get('m') || '0'
  }
}

export const wxwScreenStates = Object.freeze({
  NoConnectivity: "NO_CONNECTIVITY",
  NoConnectivityCritical: "NO_CONNECTIVITY_CRITICAL",
  NoInternet: "NO_INTERNET_SCREEN",
  NoLayout: "NO_LAYOUT_SCREEN",
  QRCode: "QR_CODE_SCREEN",
  Webview: "WEBVIEW_SCREEN",
  Loading: "LOADING_SCREEN",
  WifiSettings: "WIFI_SETTINGS_SCREEN"
});

export const fetchIntervals = Object.freeze({
  NoConnectivity: 60000,
  NoConnectivityCritical: 60000,
  NoInternet: 60000,
  NoLayout: 5000,
  QRCode: 5000,
  Widgets: 60000,
  WidgetsBackground: 10 * 60000,
  Forecast: 30 * 60000,
  NewVersion: 1000 * 60 * 60 * 6, // 6 hours
});

export const MAX_NO_CONNECTION_TIME_MILLIS = 15 * 60000;

const pm10_breakpoints = [
  [0, 54],
  [55, 154],
  [155, 254],
  [255, 354],
  [355, 424],
  [425, 504],
  [505, 999],
];


const aqi_breakpoints = [
  [0, 50],
  [51, 100],
  [101, 150],
  [151, 200],
  [201, 300],
  [301, 400],
  [401, 500],
];

export const calculatePm10AQI = (concentration) => {
  concentration = Math.floor(concentration);
  return aqi(concentration, pm10_breakpoints);
};

export const aqi = (concentration, breakpoints) => {
  var index = breakpointIndex(concentration, breakpoints);

  if (-1 === index) {
    return NaN;
  }

  var i_high = aqi_breakpoints[index][1],
      i_low = aqi_breakpoints[index][0],
      c_high = breakpoints[index][1],
      c_low = breakpoints[index][0];

  return Math.round((i_high - i_low) / (c_high - c_low) * (concentration - c_low) + i_low);
}

function breakpointIndex (value, breakpoints) {
  return breakpoints.findIndex(function (breakpoint) {
    if (null === breakpoint) {
      return false;
    }
    return breakpoint[0] <= value && value <= breakpoint[1];
  });
}