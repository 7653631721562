import "./AQIN.scss";
import { CardFooter } from "../../components/CardFooter/CardFooter";
import FormattedDataPoint from "../../components/FormattedDataPoint/FormattedDataPoint";
import { ReactComponent as Arrow } from '../../assets/aqin-arrow.svg';
import { useWidth } from "../../hooks/useWidth";
import { calculatePm10AQI } from "../../utils";

const sectionWidth = 30;
const min = 0;
const max = 180;

export function AQIN({ currentDevice, size }) {
  const [width, ref, , , footerStyle] = useWidth(size);

  let aqiPm25Aqin = currentDevice.lastData.aqi_pm25_aqin;
  if (aqiPm25Aqin > 500) {
    aqiPm25Aqin = 500
  }

  let aqiPm10Aqin = calculatePm10AQI(currentDevice.lastData.pm10_in_aqin);
  if (aqiPm10Aqin > 500) {
    aqiPm10Aqin = 500
  }

  let aqi;
  if (Number.isFinite(aqiPm25Aqin) && aqiPm25Aqin >= (aqiPm10Aqin ? aqiPm10Aqin : 0)) {
    aqi = aqiPm25Aqin;
  } else {
    aqi = aqiPm10Aqin;
  }

  const total = max - min;
  const totalAngle = 180;
  const initialArrowAngleOffset = -90;
  // const cCenterLeftPosition = 299;
  // const cCenterTopPosition = 247;
  const offset = aqi - min;
  const angle = (totalAngle * offset) / total;
  let section = Math.ceil(angle / sectionWidth);
  if (section === 0) section = 1;

  const pm25OutdoorBands = [
    {
      cutoff: [0, 50],
      label: "Good",
      degrees: [3.2, 30.3],
      left: 285,
      top: 238,
      radius: 180
    },
    {
      cutoff: [51, 100],
      label: "Moderate",
      degrees: [32.2, 60],
      left: 285,
      top: 238,
      radius: 178
    },
    {
      cutoff: [101, 150],
      label: "Unhealthy for Sensitive Groups",
      degrees: [61, 89.6],
      left: 285,
      top: 238,
      radius: 178
    },
    {
      cutoff: [151, 200],
      label: "Unhealthy",
      degrees: [90.4, 119.2],
      left: 285,
      top: 238,
      radius: 178
    },
    {
      cutoff: [201, 300],
      label: "Very Unhealthy",
      degrees: [120.2, 148],
      left: 285,
      top: 238,
      radius: 178
    },
    {
      cutoff: [301, 500],
      label: "Hazardous",
      degrees: [150, 176.7],
      left: 285,
      top: 238,
      radius: 180
    },
  ];

  const degreesToRadians = (degrees) => degrees * (Math.PI / 180);

  const stuffForpm25Outdoor = (value) => {
    let v = value > 500 || isNaN(value) ? 500 : value;
    const pm25OutdoorBand = pm25OutdoorBands.find(
        (band) => v <= band.cutoff[1]
    );
    let angleDeg =
        pm25OutdoorBand.degrees[0] +
        ((v - pm25OutdoorBand.cutoff[0]) *
            (pm25OutdoorBand.degrees[1] - pm25OutdoorBand.degrees[0])) /
        (pm25OutdoorBand.cutoff[1] - pm25OutdoorBand.cutoff[0]);

    const circleRadius = pm25OutdoorBand.radius;
    const angleRad = degreesToRadians(angleDeg);
    const topOffset = Math.abs(circleRadius * Math.sin(angleRad));
    const leftOffset =
        Math.abs(circleRadius * Math.cos(angleRad)) *
        (angleDeg < 90 ? -1 : 1);

    const arrowStyle = {
      transform: `rotate(${initialArrowAngleOffset + angleDeg}deg)`,
      top:  (pm25OutdoorBand.top- topOffset) + 'px',
      left: (pm25OutdoorBand.left + leftOffset) + 'px',
      position: "absolute"
    };
    return {
      ...pm25OutdoorBand,
      arrowStyle,
      v,
    };
  };

  const stuffs = {};

  if (Number.isFinite(aqiPm25Aqin)) {
    stuffs.aqi25 = stuffForpm25Outdoor(aqiPm25Aqin);
  }
  if (Number.isFinite(aqiPm10Aqin)) {
    stuffs.aqi10 = stuffForpm25Outdoor(aqiPm10Aqin);
  }

  // let cRadius = 193;
  if (aqi === aqiPm25Aqin) {
    stuffs.aqi = stuffs.aqi25;
  } else {
    stuffs.aqi = stuffs.aqi10;
  }
  return (
      <>
        <div className="metric-card-body" ref={ref}>
          {size === "large-card" && Number.isFinite(aqiPm25Aqin) ? <Arrow className="arrow" style={stuffs.aqi25.arrowStyle}/> : ""}
          {size === "large-card" && Number.isFinite(aqiPm10Aqin) ? <Arrow className="arrow" style={stuffs.aqi10.arrowStyle}/> : ""}
          {/*<svg style={{*/}
          {/*  top: (cCenterTopPosition - cRadius) + "px",*/}
          {/*  left: (cCenterLeftPosition - cRadius) + "px",*/}
          {/*  position: "absolute" // Ensure the position is specified*/}
          {/*}}*/}
          {/*     width={(2 * cRadius) + "px"} height={(2 * cRadius) + "px"}*/}
          {/*     viewBox={(cRadius * -1) + " " + (cRadius * -1) + " " + (2 * cRadius) + " " + (2 * cRadius)}*/}
          {/*     xmlns="http://www.w3.org/2000/svg">*/}
          {/*  <circle*/}
          {/*      cx="0"*/}
          {/*      cy="0"*/}
          {/*      r={cRadius}*/}
          {/*      fill="none"*/}
          {/*      stroke="red"*/}
          {/*      strokeWidth="1"*/}
          {/*  />*/}
          {/*</svg>*/}
          <div className='big-value'>
            <FormattedDataPoint
                row={currentDevice.lastData}
                value={aqi}
                className='big-number'
                center={true}
            />
          </div>
        </div>
        <CardFooter values={[
          {
            name: "PM2.5",
            value: <FormattedDataPoint
                value={aqiPm25Aqin}
                row={currentDevice.lastData}
                type={'aqi_pm25_aqin'}
                suff=" "
                fontStyles={{
                  mainStyle: footerStyle,
                  suffixStyle: footerStyle,
                }}
                availableWidth={width / 3}
                roundDecimals={true}
            />
          },
          {name: stuffs.aqi.label, value: ''},
          {
            name: "PM10",
            value: <FormattedDataPoint
                value={aqiPm10Aqin}
                row={currentDevice.lastData}
                suff=" "
                fontStyles={{
                  mainStyle: footerStyle,
                  suffixStyle: footerStyle,
                }}
                availableWidth={width / 3}
                roundDecimals={true}
            />
          }
        ]}
                    size={size}
        />
      </>
  );
}
